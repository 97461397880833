import { WP_REST_API_Post } from "wp-types"
import styles from './Section.module.scss';
import classNames from "classnames";

type props = {
  post: WP_REST_API_Post,
  className: string,
  id: string
}

export default function Section ({post, className, id} : props) {

  const bg_image = post.featured_image_src && post.featured_image_src.replace('-600x400', '');

  return <div 
    style={{backgroundImage: `url(${bg_image})`}} 
    className={classNames(styles.wrapper, className)}
    id={id}
  >
    <div className={styles.content_wrapper}>
      <div className={styles.inner_wrapper}>
        <h2>{post.title.rendered}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
      </div>
    </div>
  </div>
}