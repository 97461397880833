import styles from './Loading.module.scss';

import logo_white from '../../svg/logo_white.svg';

type props = {
  type: 'fullpage'
}

export function Loading ({type} : props) {

  return type === 'fullpage'
    ? <div className={styles.wrapper}>
        <div className={styles.inner_wrapper}>
          <img src={logo_white} alt='W' className={styles.logo} />
          <div className={styles.loading_line}></div>
        </div>
      </div>
    : <>Small loading</>

}