import { WP_REST_API_Posts } from "wp-types"
import Section from "./Section"
import { Element} from 'react-scroll';

type props = {
  posts: WP_REST_API_Posts
}

export default function Sections ({posts} : props) {

  
  return <div>
    {posts && posts.map((post, i) => {
    
    const id = post.title.rendered.replace(/[åäö]/g, 'aao').replace(/[^A-Z0-9]+/ig, "").toLowerCase();

    return <Element name={id}>
        <Section 
          className="section_observer" 
          post={post} 
          key={post.id}
          id={id}
        />
      </Element>
    })}
  </div>
}