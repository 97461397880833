import { WP_REST_API_Posts } from 'wp-types';
import Header from './components/header/Header';
import { useFetch } from './utils/useFetch';
import { getUrl } from './utils/getUrl';
import { useEffect } from 'react';
import { Loading } from './components/loading/Loading';
import Sections from './components/Sections';
import { UnderConstruction } from './components/loading/UnderConstruction';


function App() {

  const dev = process.env.NODE_ENV === 'development';

  const {data, loading, error} = useFetch(getUrl('wp-json/wp/v2/posts/'));

  useEffect(() => {
    const cssAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', cssAppHeight)
    cssAppHeight();
    
  }, []);

  

  const _posts = data as unknown as WP_REST_API_Posts;
  const posts = _posts && _posts.sort((a,b) => a.id - b.id)
  const links = posts && posts.reduce((links, post) => {
    return [
      ...links,
      post.title.rendered
    ]
  }, [] as string[])

  return loading 
      ? <Loading type='fullpage' />
      : <div>
          {/* <Header links={links} /> */}
          <Sections posts={posts} />
        </div>

  /* return !dev 
    ? <UnderConstruction type='fullpage' />
    :loading 
      ? <Loading type='fullpage' />
      : <div>
          <Header links={links} />
          <Sections posts={posts} />
        </div> */
}

export default App;
